<template>
  <Layout class="AdvertisementEdit">
    <div class="content">
      <h4 class="title">
        信息
      </h4>
      <el-form
        ref="form"
        v-loading="fetchLoading"
        :model="formData"
        :rules="rules"
        label-width="110px"
        label-position="left"
      >
        <el-form-item
          label="排序"
          prop="sort"
        >
          <el-input-number
            v-model="formData.sort"
            :min="0"
            clearable
          />
        </el-form-item>
        <el-form-item label="所属板块">
          <el-select
            v-model="formData.pId"
            disabled
          >
            <el-option
              v-for="(item, index) in positionList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="源"
          prop="adSource"
        >
          <el-select
            v-model="formData.adSource"
            style="width: 600px"
          >
            <el-option
              v-for="item in adSourceList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="单元名称"
          prop="title"
        >
          <el-input
            v-model="formData.title"
            placeholder="单行输入"
            style="width: 600px"
          />
        </el-form-item>
        <el-form-item
          label="单元 ID"
          prop="adId"
        >
          <el-input
            v-model="formData.adId"
            placeholder="单行输入"
            style="width: 600px"
          />
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-switch
            v-model="formData.status"
            :active-value="1"
            :inactive-value="2"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <template>
          <el-form-item
            label="定向区域"
            prop="showNations"
          >
            <div class="setting-nations">
              <el-button
                type="text"
                @click="handleShowSelectNation"
              >
                编辑国家
              </el-button>
            </div>
          </el-form-item>
          <section
            style="width: 720px;"
            class="nationList"
            :class="{
              open: showAllNation
            }"
          >
            <el-table :data="formData.showNations">
              <el-table-column
                label="国家/地区"
                prop="nation"
                min-width="200"
              />
              <el-table-column
                label="状态"
                width="120"
              >
                展示
              </el-table-column>
            </el-table>
          </section>
        </template>
        <div
          v-if="formData.showNations.length > 3"
          class="show-more"
        >
          <span
            v-if="!showAllNation"
            @click="showAllNation = true"
          >点击展开 <i class="el-icon-arrow-down" /></span>

          <span
            v-else
            @click="showAllNation = false"
          >点击收起 <i class="el-icon-arrow-up" /></span>
        </div>
      </el-form>

      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>

    <el-drawer
      title="编辑国家"
      :visible.sync="showSelectNation"
      direction="rtl"
      @close="handleSelectClose"
    >
      <el-table
        ref="multipleTable"
        :data="nationList"
        style="width: 100%"
        height="calc(100vh - 200px)"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          label="国家/地区"
          prop="nation"
          min-width="120"
        />
      </el-table>
      <div class="select-area">
        <span>选择要定向的国家</span>
        <div>
          <el-button
            type="text"
            @click="handleCancelSelect"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            plain
            @click="handleConfirmSelect"
          >
            确定
          </el-button>
        </div>
      </div>
    </el-drawer>
  </Layout>
</template>

<script>
import { cloneDeep } from 'lodash'
/* eslint-disable */
import { queryADInfo, updateOrSaveAdvertisement, verifyAdvertisingId } from '@/api/APP_Category'
import { mapState } from 'vuex'

import {
  getUserNationStatistics
} from '@/api/electronicEntity.js'

const adSourceList = process.env.VUE_APP_ENVIRONMENT === 'prod'
? [
  'ca-app-pub-6764968036722256~7333933688',
  'ca-app-pub-6422383899031067~6476823606'
]
: [
  'ca-app-pub-3940256099942544~3347511713',
  'ca-app-pub-6764968036722256~7333933688',
  'ca-app-pub-6422383899031067~6476823606'
]
export default {
  name: 'RealADEdit',
  data () {
    const showNationsValidator = (rule, value, callback) => {
      if (this.formData.showNations.length) {
        return callback()
      } else {
        callback(new Error('至少选择一个国家'))
      }
    }

    const repeatValidator = (rule, value, callback) => {
      verifyAdvertisingId({
        id: this.formData.id || '',
        adId: value
      }).then(res => {
        if (!res.data.verifyFalg) {
          callback()
        } else {
          callback(new Error('单元ID已存在'))
        }
      })
    }
    return {
      positionList: [],
      adSourceList,
      isADEdit: true,
      adKey: '',
      isCreated: '',
      formData: {
        adSource: (() => adSourceList[0])(),
        status: 2,
        showNations: []
      },
      updateLoading: false,
      rules: {
        title: [{ required: true, message: '单元名称必填' }],
        adSource: [
          { required: true, message: '源必填' }
        ],
        adId: [

          { required: true, message: '单元ID必填' },
          { validator: repeatValidator }
        ],
        sort: [{ required: true, message: '排序必填' }],
        showNations: [{ required: true, validator: showNationsValidator }]
      },
      fetchLoading: false,
      style: '',
      nationList: [],
      showSelectNation: false,
      tempSelectionRows: [],
      showAllNation: false
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel'])
  },
  created () {
    this.isADEdit = this.$route.name === 'RealADEdit'
    this.style = this.$route.query.style + ''
    this.formData.pId = Number(this.$route.query.pId)
    this.formData.zoneId = Number(this.$route.query.zoneId)
    this.positionList = [
      { name: this.$route.query.name || 'Error', value: Number(this.$route.query.pId) }
    ]
    if (this.isADEdit) {
      this.handleQueryADInfo()
    }
    this.handleSearchNation()
  },
  methods: {
    handleSearchNation () {
      getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          this.nationList = res.data.list.map(item => {
            const { countryCode, id, nation } = item
            return { countryCode, id, nation }
          })
        }
      })
    },
    handleQueryADInfo () {
      const id = this.$route.query.id
      this.fetchLoading = true
      queryADInfo({
        id,
        appId: this.appId,
        channel: this.channel
      })
        .then(res => {
          if (res.code === 200) {
            this.formData = res.data.oneData
            this.formData.zoneId = Number(this.$route.query.zoneId)
            this.formData.pId = Number(this.$route.query.pId)
            this.formData.showNations = JSON.parse(this.formData.showNations || '{}')
          }
        })
        .finally(() => {
          this.fetchLoading = false
        })
    },

    handleSubmit () {
      this.updateLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = this.preFormatFormData()
          updateOrSaveAdvertisement({
            ...formData,
            appId: this.appId,
            channel: this.channel
          })
            .then(res => {
              if (res.code === 200) {
                const message = this.isCreated ? '新建成功' : '更新成功'
                this.$notify({
                  title: '成功',
                  message: message,
                  type: 'success'
                })
                this.$router.go(-1)
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        } else {
          this.updateLoading = false
        }
      })
    },
    handleCancel () {
      this.$router.back()
    },
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      formData.showNations = JSON.stringify(formData.showNations)
      return formData
    },

    handleShowSelectNation () {
      this.showSelectNation = true
      const selectionIdList = this.formData.showNations.map(item => item.id)
      const selectionList = this.nationList.filter(item => {
        return selectionIdList.includes(item.id)
      })
      this.$nextTick(() => {
        selectionList.forEach(item => {
          this.$refs.multipleTable.toggleRowSelection(item, true)
        })
      })
    },
    handleSelectClose () {
      this.$refs.multipleTable.clearSelection()
    },
    handleSelectionChange (selection) {
      this.tempSelectionRows = selection
    },
    handleCancelSelect () {
      this.showSelectNation = false
    },
    handleConfirmSelect () {
      this.formData.showNations = this.tempSelectionRows
      this.showSelectNation = false
    }
  }
}
</script>
<style lang="less">

  .AdvertisementEdit{
    .el-input-number--medium{
      width: 217px;
    }
  }
</style>
<style scoped lang="less">
  .AdvertisementEdit{
    .content{
      /*width: 500px;*/
      margin: 50px 80px;
    }
  }
  .nationList {
    height: 180px;
    overflow: hidden;
    &.open {
      height: auto;
      overflow: auto;
    }
  }
  .show-more {
    width: 780px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 14px;
    color: #aaa;
    >span {
      cursor: pointer;
    }
  }
  .setting-nations {
    width: 600px;
    display: flex;
    justify-content: flex-end;
  }
  .select-area {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  h4.title{
    margin-bottom: 50px;
  }
  .el-input,
  .el-textarea{
    width: 420px;
  }
  .ctrl-area{
    margin-top: 80px;
    .el-button{
      padding:10px  40px;
    }
  }
</style>
